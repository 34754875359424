<template>
  <div class="staff height1">
    <!--      头部      -->
    <bread></bread>
    <!--    内容    -->
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top" style="margin-bottom: 0">
          <el-button type="danger" size="small" @click="AddOrganizationBtn" plain>添加下级机构</el-button>
        </div>
        <div class="table-content">
          <el-row class="table-list" v-for="item in OrganizationData" :gutter="20">
            <el-col :span="14">
              <div class="list-left clearfix">
                <div class="list-img left">
                  <img src="../../../assets/img/login_img.png" alt="" />
                </div>
                <div class="list-mes left">
                  <h3>
                    {{ item.name }} <span>{{ item.type ? item.type.name : '' }} {{ item.type && item.level ? '/' : '' }} {{ item.level ? item.level.name : '' }}</span>
                  </h3>
                  <p>
                    <span v-show="item.phone">联系方式：{{ item.phone }}</span> <span v-show="item.mail">电子邮件：{{ item.mail }}</span>
                  </p>
                  <p v-show="item.contactName || item.contactPhone">联系人 {{ item.contactName }} {{ item.contactPhone }}</p>
                  <p>
                    {{ item.region ? item.region.fullName : '' }} <span>{{ item.address }}</span>
                  </p>
                </div>
              </div>
            </el-col>
            <el-col :span="10">
              <div class="list-right text-right">
                <div class="btn-group">
                  <el-button size="mini" plain @click="EditOrganizationBtn(item)">编辑</el-button>
                  <el-button size="mini" type="primary" v-show="item.statu == -1" plain @click="EnableOrganization(item)">启用</el-button>
                  <el-button size="mini" type="danger" v-show="item.statu == 1" plain @click="DisableOrganization(item)">停用</el-button>
                </div>
                <!-- <p class="right-num">
                  <el-button type="text" style="margin-left: 10px" id="closeSearchBtn" @click="ShowUser(item.id)"> 查看用户 </el-button>
                </p> -->
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <!-- 添加下级机构 -->
    <el-drawer :visible.sync="OrganizationDialog" :direction="direction" :size="size" custom-class="demo-drawer">
      <h1 slot="title" v-html="OrganizationTitle"></h1>
      <div class="addhospital">
        <el-form ref="drawerform" :rules="rules" :model="editOrganizationData" label-width="100px">
          <el-row :gutter="30">
            <el-col :span="8">
              <div class="form-box" style="">
                <div class="headImg">
                  <div class="headimgBox">
                    <img src="../../../assets/img/login_img.png" alt="" />
                  </div>
                  <el-button class="upload-btn" type="primary" icon="el-icon-edit" circle></el-button>
                </div>
                <!-- <el-form-item label="所属产品" prop="productId">
                  <el-select v-model="editOrganizationData.productId" style="width: 100%" placeholder="请选择所属产品" disabled>
                    <el-option v-for="(item, index) in productTab" :label="item.name" :value="item.id"> </el-option>
                  </el-select>
                </el-form-item> -->
                <el-form-item label="所属行业" prop="industryId">
                  <el-select v-model="editOrganizationData.industryId" style="width: 100%" placeholder="请选择所属行业">
                    <el-option v-for="(item, index) in industryList" :label="item.name" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="机构级别" prop="levelId">
                  <el-select v-model="editOrganizationData.levelId" style="width: 100%" clearable placeholder="请选择机构级别">
                    <el-option v-for="item in LevelData" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="机构类型" prop="typeId">
                  <el-select v-model="editOrganizationData.typeId" style="width: 100%" clearable placeholder="请选择机构级别">
                    <el-option v-for="item in TypeData" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="角色类型" prop="roleId" v-if="editOrganizationData.id == 0">
                  <el-select v-model="editOrganizationData.roleId" style="width: 100%" clearable placeholder="请选择角色类型" @change="selectRoleId">
                    <el-option v-for="item in roleList" :key="item.id" :label="item.roleName" :value="item.id"> </el-option>
                  </el-select>
                </el-form-item>
                <!-- <el-form-item label="上级机构">
                  <el-select v-model="editOrganizationData.parentId" style="width: 100%" clearable placeholder="请选择上级医院">
                    <el-option v-for="item in OrganizationData" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                  </el-select>
                </el-form-item> -->
                <el-form-item label="分销模式" prop="distributionMode">
                  <el-radio-group style="display: flex; margin-top: 11px" v-model="editOrganizationData.distributionMode">
                    <el-radio :label="0">机构模式</el-radio>
                    <el-radio :label="1">个人模式</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="状态">
                  <el-radio-group size="medium" v-model="editOrganizationData.statu">
                    <el-radio-button :label="1">启用</el-radio-button>
                    <el-radio-button :label="-1">禁用</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="16">
              <div class="form-box">
                <span>机构资料</span>
                <el-divider></el-divider>
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="机构名称" prop="name">
                      <el-input v-model="editOrganizationData.name"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="机构简称" prop="name">
                      <el-input v-model="editOrganizationData.alias"></el-input>
                    </el-form-item>
                  </el-col>
                  <!-- <el-col :span="12">
                    <el-form-item label="电子邮箱" prop="mailAddress">
                      <el-input v-model="editOrganizationData.mailAddress"></el-input>
                    </el-form-item>
                  </el-col> -->
                  <el-col :span="12">
                    <el-form-item label="机构账号" prop="phone">
                      <el-input v-model="editOrganizationData.phone" :disabled="editOrganizationData.id > 0"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="联系人" prop="contactName">
                      <el-input v-model="editOrganizationData.contactName"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="联系人电话" prop="contactPhone">
                      <el-input v-model="editOrganizationData.contactPhone"></el-input>
                    </el-form-item>
                  </el-col>

                  <el-col :span="24">
                    <el-form-item label="机构地址" prop="regionId">
                      <el-cascader :placeholder="editOrganizationData.region ? editOrganizationData.region.fullName : '请选择所在区域'" v-model="editOrganizationData.regionId" :options="RegionData" size="medium" :props="{ emitPath: false, multiple: false, checkStrictly: true, lazy: true, lazyLoad: LasyLoadRegion, value: 'id', label: 'regionName', children: 'childrens' }" style="margin-right: 10px; width: 100%; margin-bottom: 22px"> </el-cascader>
                      <!-- <el-input v-model="editOrganizationData.address" placeholder="请输入详细地址">
                                                </el-input> -->
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="详细地址" prop="address">
                      <el-input placeholder="地图选点" v-model="editOrganizationData.address" disabled>
                        <!-- <template slot="prepend"> {{ addressInfo.province }} {{ addressInfo.city }}{{ addressInfo.district }}</template> -->
                      </el-input>
                    </el-form-item>
                  </el-col>

                  <el-col :span="24">
                    <baidu-map class="bm-view" scroll-wheel-zoom :center="location" :zoom="zoom" ak="C3L8oD0NokcKDAyhtGZgTtwF1R4eqo7S" @ready="mapReady">
                      <bm-view class="map" style="width: 100%; height: 100%; flex: 1"></bm-view>
                      <!-- 自定义控件 --><!-- 自动填充 -->
                      <bm-control>
                        <bm-auto-complete :sugStyle="{ zIndex: 999999 }">
                          <el-input size="small" type="text" id="searchAddres" v-model="searchAddresKeywords" placeholder="请输入地点" @change="confirmAddress"> </el-input>
                        </bm-auto-complete>
                      </bm-control>
                      <!-- 手动放大缩小到省市街道 位置：右下方-->
                      <bm-navigation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" />
                      <bm-geolocation anchor="BMAP_ANCHOR_TOP_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>
                    </baidu-map>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="机构介绍">
                      <quill-editor v-model="editOrganizationData.abstract" />
                    </el-form-item>
                  </el-col>
                  <el-col :span="24">
                    <el-form-item label="营业执照" prop="businessImage">
                      <el-upload :headers="headers" :action="fileUploadUrl" class="avatar-uploader" :limit="1" list-type="picture-card" :show-file-list="false" :on-success="uploadBusinessImage">
                        <el-image v-if="editOrganizationData.businessImage" :src="`${this.Services.Authorization}${editOrganizationData.businessImage}`" class="avatar" />
                        <el-icon v-else class="avatar-uploader-icon">
                          <Plus />
                        </el-icon>
                      </el-upload>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="身份证正面" prop="idFrontImage">
                      <el-upload :headers="headers" :action="fileUploadUrl" class="avatar-uploader" :limit="1" list-type="picture-card" :show-file-list="false" :on-success="uploadIDFrontImage">
                        <el-image v-if="editOrganizationData.idFrontImage" :src="`${this.Services.Authorization}${editOrganizationData.idFrontImage}`" class="avatar" />
                        <el-icon v-else class="avatar-uploader-icon">
                          <Plus />
                        </el-icon>
                      </el-upload>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="身份证反面" prop="idBackImage">
                      <el-upload :headers="headers" :action="fileUploadUrl" class="avatar-uploader" :limit="1" list-type="picture-card" :show-file-list="false" :on-success="uploadIDBackImage">
                        <el-image v-if="editOrganizationData.idBackImage" :src="`${this.Services.Authorization}${editOrganizationData.idBackImage}`" class="avatar" />
                        <el-icon v-else class="avatar-uploader-icon">
                          <Plus />
                        </el-icon>
                      </el-upload>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-divider></el-divider>
                <el-row>
                  <el-col :span="24">
                    <el-col :span="12">
                      <el-form-item label="用户账号" prop="phone">
                        <el-input v-model="editOrganizationData.phone" autocomplete="off" disabled> </el-input>
                        <el-button type="text" style="position: absolute; top: -3px"><i class="el-icon-question" style="font-size: 18px"></i>登录账号为手机号</el-button>
                      </el-form-item>
                    </el-col>
                  </el-col>

                  <el-col :span="12">
                    <el-form-item label="登录密码" v-if="!editOrganizationData.id" prop="password">
                      <el-input v-model="editOrganizationData.password" show-password autocomplete="off" :disabled="disabled"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="确认密码" v-if="!editOrganizationData.id" prop="confirmPwd">
                      <el-input v-model.trim="editOrganizationData.confirmPwd" show-password autocomplete="off" :disabled="disabled"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-checkbox v-model="editOrganizationData.checked" v-if="!editOrganizationData.id" style="margin-left: 32px" @change="isChecked2">使用默认密码 <span style="color: #0c9a9a">(默认为：123456)</span></el-checkbox>
                </el-row>
                <div slot="footer" class="demo-drawer__footer">
                  <el-button @click="cancelDialog('drawerform')">取消</el-button>
                  <el-button type="primary" @click="saveOrganzation('drawerform')">保存</el-button>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-form>
        <!--<tinymce-editor ref="editor" v-model="value"></tinymce-editor>-->
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { Product } from '../../../components/HospitalDomain/Product.js'
import { Industry } from '../../../components/HospitalDomain/Industry.js'
import bread from '../../../components/common/breadcrumb'
import { Department } from '../../../components/HospitalDomain/Department'
import { Region } from '../../../components/HospitalDomain/Region'
import { Organization } from '../../../components/HospitalDomain/Organization'
import { OrganizationLevel } from '../../../components/HospitalDomain/OrganizationLevel'
import { OrganizationType } from '../../../components/HospitalDomain/OrganizationType'
import { Role } from '../../../components/HospitalDomain/Role'
export default {
  name: 'Organization',
  components: {
    bread,
  },
  computed: {
    headers() {
      return {
        Authorization: 'Bearer' + ' ' + JSON.parse(sessionStorage.getItem(this.ClientId + 'Token')).access_token,
      }
    },
  },
  data() {
    var product = new Product(this.TokenClient, this.Services.Authorization)
    var industry = new Industry(this.TokenClient, this.Services.Authorization)
    var department = new Department(this.TokenClient, this.Services.Authorization)
    var role = new Role(this.TokenClient, this.Services.Authorization)
    var region = new Region(this.TokenClient, this.Services.Authorization)
    var organization = new Organization(this.TokenClient, this.Services.Authorization)
    var organizationLevel = new OrganizationLevel(this.TokenClient, this.Services.Authorization)
    var organizationType = new OrganizationType(this.TokenClient, this.Services.Authorization)
    return {
      IndustryDomain: industry,
      Product: product,
      Region: region,
      roleList: [],
      Department: department,
      OrganizationDomain: organization,
      OrganizationLevel: organizationLevel,
      OrganizationType: organizationType,
      RoleDomain: role,
      fileUploadUrl: `${this.Services.Authorization}/api/File/upload`,
      productTab: [],
      industryList: [],
      RegionData: [],
      LevelData: [],
      TypeData: [],
      DepartmentData: [],
      disabled: false,
      OrganizationData: [],
      organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
      userInfo: JSON.parse(sessionStorage.getItem('userInfo')),
      OrganizationTitle: '',
      OrganizationDialog: false,
      editOrganizationData: {
        id: 0,
        name: '',
        alias: '',
        address: '',
        phone: '',
        mailAddress: '',
        contactName: '',
        contactPhone: '',
        productId: null,
        industryId: null,
        levelId: null,
        typeId: null,
        status: true,
        regionId: null,
        abstract: '',
        parentId: null,
        distributionMode: 0,
        EditItem: null,
        longitude: 0,
        latitude: 0,
        businessImage: '',
        idFrontImage: '',
        idBackImage: '',
        roleId: null,
        organizationManager: {
          // roleId: null,
        },
      },
      searchAddresKeywords: '',
      // 百度地图参数
      addressInfo: {
        // 地址信息
        longitude: '', // 经度
        latitude: '', // 纬度
        province: '', // 省
        city: '', // 市
        district: '', // 区
        address: '', // 详细地址
      },
      centerDialog: false,
      location: { lng: 116.403961, lat: 39.915112 }, // 设置中心点坐标lng: 116.403961, lat: 39.915112;
      zoom: 14,
      map: {},
      rules: {
        userName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '请输入合法手机号', trigger: 'blur' },
        ],
        telephone: [{ pattern: /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/, message: '请输入合法固话号', trigger: 'blur' }],
        mailAddress: [{ type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' }],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            pattern: /^(?![\s\u4e00-\u9fa5]+$).{6,16}$/,
            message: '密码为6~16个英文字母、数字、符号组成！',
            trigger: 'blur',
          },
        ],
        confirmPwd: [{ required: true, message: '请再次输入密码', trigger: 'blur' }],
        birthday: [
          { required: true, message: '请选择时间', trigger: 'change' },
          { type: 'date', message: '请选择时间', trigger: 'change' },
        ],
        departmentId: [{ required: true, message: '请选择所属部门', trigger: 'change' }],
        productId: [{ required: true, message: '请选择所属产品', trigger: 'change' }],
        industryId: [{ required: true, message: '请选择所属行业', trigger: 'change' }],
        sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
        userPostId: [{ required: true, message: '请选择用户职位', trigger: 'change' }],
        userTitleId: [{ required: true, message: '请选择用户职称', trigger: 'change' }],
        roleId: [{ required: true, message: '请选择用户角色', trigger: 'change' }],
        name: [{ required: true, message: '请输入医院名称', trigger: 'blur' }],
        contactName: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        levelId: [{ required: true, message: '请选择机构级别', trigger: 'change' }],
        typeId: [{ required: true, message: '请选择机构类型', trigger: 'change' }],
        regionId: [{ required: true, message: '请选择地址', trigger: 'change' }],
        versionsId: [{ required: true, message: '请选择版本', trigger: 'change' }],
        mZRate: [{ required: true, message: '请输入比例', trigger: 'blur' }],
        content: [{ required: true, message: '请输入文本', trigger: 'blur' }],
        logoPath: [{ required: true, message: '请上传图片', trigger: 'blur' }],
        customerName: [{ required: true, message: '请输入客服名称', trigger: 'blur' }],
        customerPhone: [
          { required: true, message: '请输入手机号或固话', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$|^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/, message: '请输入合法手机号或固话', trigger: 'blur' },
        ],
        address: [{ required: true, message: '请地图选择详细地址', trigger: 'blur' }],
      },
      direction: 'ttb',
      size: '100%',
      AddUserDialog: false,
      DialogName: '',
    }
  },
  mounted() {
    this.getList()
    this.GetProductAll()
    this.FindIndustry(this.userInfo.productId)
    this.InitialLevel(this.userInfo.productId)
    this.InitialType(this.userInfo.productId)
    // this.InitialOrganization()
  },
  methods: {
  
    InitialOrganization() {
      var _this = this;
      _this.OrganizationData = [];
      _this.OrganizationDomain.Hospital(
        this.userInfo.productId,
        '',
        function (data) {
          _this.OrganizationData = data.data.filter(function (item) {
            item.Users = [];
            return item;
          });
        },
        function (err) {
          console.log(err);
          _this.OrganizationData = [];
        }
      );
    },
    isChecked2(data) {
      var _this = this
      _this.disabled = data
      if (data) {
        _this.editOrganizationData.password = '123456'
        _this.editOrganizationData.confirmPwd = '123456'
      } else {
        _this.editOrganizationData.password = ''
        _this.editOrganizationData.confirmPwd = ''
      }
    },
    cancelDialog(formName) {
      this.OrganizationDialog = false
      this.$refs[formName].resetFields()
    },
    saveOrganzation(formName) {
      var _this = this
      var item = _this.editOrganizationData
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          item.region = null
          item.mail = item.mailAddress
          item.productId = parseInt(item.productId)
          if (item.id) {
            _this.OrganizationDomain.EditHospital(
              item,
              function (data) {
                _this.getList()
                _this.OrganizationDialog = false
              },
              function (err) {
                console.log(err)
                var jsonObj = JSON.parse(err.msg)
                if (jsonObj.code == '400') {
                  _this.$message({
                    type: 'error',
                    message: jsonObj.msg,
                  })
                }
              }
            )
          } else {
            if (_this.editOrganizationData.password == '') {
              this.$message.error('请输入密码')
              return false
            }
            if (_this.editOrganizationData.password != _this.editOrganizationData.confirmPwd) {
              this.$message.error('两次密码输入不同，请重新输入')
              return false
            }
            item.organizationManager.id = 0
            item.organizationManager.userName = _this.editOrganizationData.contactName
            item.organizationManager.password = _this.editOrganizationData.password
            item.organizationManager.confirmPwd = _this.editOrganizationData.confirmPwd
            item.organizationManager.phone = _this.editOrganizationData.phone
            _this.OrganizationDomain.AddHospital(
              item,
              function (data) {
                _this.getList()
                _this.OrganizationDialog = false
              },
              function (err) {
                console.log(err)
                var jsonObj = JSON.parse(err.msg)
                if (jsonObj.code == '400') {
                  _this.$message({
                    type: 'error',
                    message: jsonObj.msg,
                  })
                }
              }
            )
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    uploadBusinessImage(res, file) {
      this.editOrganizationData.businessImage = `${res[0].completeFilePath}`.trim()
    },
    uploadIDFrontImage(res, file) {
      this.editOrganizationData.idFrontImage = `${res[0].completeFilePath}`.trim()
    },
    uploadIDBackImage(res, file) {
      this.editOrganizationData.idBackImage = `${res[0].completeFilePath}`.trim()
    },
    preview(uploadFile) {
      console.log(uploadFile)
      dialogImageUrl.value = uploadFile.url
      dialogVisible.value = true
    },
    // 搜索框的搜索事件
    confirmAddress(e) {
      let that = this
      that.map.clearOverlays() // 移除地图上的标注
      var local = new BMap.LocalSearch(that.map, {
        //智能搜索
        onSearchComplete: function (results) {
          let poi = results.getPoi(0) //获取第一个智能搜索的结果
          var searchpt = poi.point // 获取坐标
          that.map.centerAndZoom(searchpt, 19)
          that.map.addOverlay(new BMap.Marker(searchpt)) //添加标注
          that.geocAddress(searchpt) // 逆地址解析
        },
      })
      // 搜索词
      local.search(e)
    },
    mapReady({ BMap, map }) {
      let that = this
      that.map = map
      map.clearOverlays() // 移除地图上的标注
      // 定位当前位置偏差较大
      if (that.addressInfo.longitude && that.addressInfo.latitude) {
        var searchpt = {
          lng: that.addressInfo.longitude,
          lat: that.addressInfo.latitude,
        }
        map.centerAndZoom(new BMap.Point(that.addressInfo.longitude, that.addressInfo.latitude), 15)
        var marker = new BMap.Marker(searchpt) // 创建标注
        map.addOverlay(marker) // 将标注添加到地图中
      } else {
        var geolocation = new BMap.Geolocation()
        geolocation.getCurrentPosition(
          function (r) {
            if (this.getStatus() == BMAP_STATUS_SUCCESS) {
              map.centerAndZoom(new BMap.Point(r.point.lng, r.point.lat), 15)
            }
          },
          { enableHighAccuracy: true }
        )
      }

      // 添加地图点击事件
      map.addEventListener('click', function (e) {
        var clickpt = e.point // 点击的坐标
        map.clearOverlays() // 移除地图上的标注
        var marker = new BMap.Marker(clickpt) // 创建标注
        map.addOverlay(marker) // 将标注添加到地图中
        // 逆地址解析
        that.geocAddress(clickpt)
      })
    },
    /** 逆向解析地址 point */
    geocAddress(point) {
      let that = this
      var geoc = new BMap.Geocoder()
      geoc.getLocation(point, function (e) {
        // 设置基本信息
        var addressInfo = e.addressComponents
        that.addressInfo.longitude = point.lng
        that.addressInfo.latitude = point.lat
        that.addressInfo.province = addressInfo.province
        that.addressInfo.city = addressInfo.city
        that.addressInfo.district = addressInfo.district
        let address = addressInfo.street + addressInfo.streetNumber
        if (e.surroundingPois.length > 0) {
          address = address + e.surroundingPois[0].title
        }
        that.addressInfo.address = address
        that.editOrganizationData.longitude = that.addressInfo.longitude
        that.editOrganizationData.latitude = that.addressInfo.latitude
        that.editOrganizationData.address = that.addressInfo.address
      })
    },
    selectRoleId() {
      this.editOrganizationData.organizationManager.roleId = this.editOrganizationData.roleId
      console.log(this.editOrganizationData.organizationManager.roleId)
    },
    //获取所有产品
    GetProductAll() {
      const _this = this
      _this.Product.ProductAll(
        function (data) {
          _this.productList = data.data
          _this.productTab.splice(0)
          for (var arrIndex = 0; arrIndex < data.data.length; arrIndex++) {
            _this.productTab.push({
              id: data.data[arrIndex].id.toString(),
              name: data.data[arrIndex].name,
              description: data.data[arrIndex].description,
            })
          }
        },
        function (error) {
          console.log(error)
        }
      )
    },
    FindIndustry(id) {
      var _this = this
      _this.IndustryDomain.IndustryByProductId(
        id,
        '',
        function (data) {
          _this.industryList = data.data
        },
        function (error) {
          console.log(error)
        }
      )
    },
    InitialRoleByProduceId(productId) {
      var _this = this
      _this.roleList = []
      _this.RoleDomain.GetRoleByProductId(
        productId,
        '',
        function (data) {
          _this.roleList = data.data.filter((i) => i.roleKey == 'SuperManager' && i.roleName.indexOf('机构') > -1)
          _this.editOrganizationData.organizationManager.roleId = _this.roleList[0].id
          _this.editOrganizationData.roleId = _this.roleList[0].id
        },
        function (err) {
          console.log(err)
        }
      )
    },
    InitialLevel(id) {
      var _this = this
      _this.LevelData = []
      _this.OrganizationLevel.LevelByProduct(
        id,
        function (data) {
          _this.LevelData = [].slice.call(data.data)
        },
        function (err) {
          console.log(err)
          _this.LevelData = []
        }
      )
    },
    InitialType(id) {
      var _this = this
      _this.TypeData = []
      _this.OrganizationType.OrganizationTypeByProduct(
        id,
        function (data) {
          _this.TypeData = [].slice.call(data.data)
        },
        function (err) {
          console.log(err)
          _this.TypeData = []
        }
      )
    },
    LasyLoadRegion(node, resolve) {
      var _this = this
      _this.Region.Regions(
        node.value,
        function (data) {
          for (var dataIndex = 0; dataIndex < data.data.length; dataIndex++) {
            data.data[dataIndex].leaf = data.data[dataIndex].regionLevel >= 5
          }
          resolve(data.data)
        },
        function (err) {
          resolve([])
        }
      )
    },
    //获取列表
    getList() {
      var _this = this
      _this.OrganizationDomain.SubOrganz(
        _this.organizationId,
        function (data) {
          _this.OrganizationData = data.data
        },
        function (err) {
          console.log(err)
        }
      )
    },
    AddOrganizationBtn(item) {
      this.OrganizationDialog = true
      this.OrganizationTitle = '新增下级机构'
      this.editOrganizationData.id = 0
      this.editOrganizationData.name = ''
      this.editOrganizationData.alias = ''
      this.editOrganizationData.address = ''
      this.editOrganizationData.phone = ''
      this.editOrganizationData.mailAddress = ''
      this.editOrganizationData.contactName = ''
      this.editOrganizationData.contactPhone = ''
      this.editOrganizationData.industryId = null
      this.editOrganizationData.levelId = null
      this.editOrganizationData.typeId = null
      this.editOrganizationData.businessImage = ''
      this.editOrganizationData.idFrontImage = ''
      this.editOrganizationData.idBackImage = ''
      this.editOrganizationData.distributionMode = 0
      this.editOrganizationData.statu = 1
      this.addressInfo.longitude = 0
      this.addressInfo.latitude = 0
      //   this.editOrganizationData.parentId = this.organizationId
      this.editOrganizationData.productId = this.userInfo.productId.toString()
      this.editOrganizationData.industryId = null
      this.editOrganizationData.organizationManager.roleId = null
      this.editOrganizationData.regionId = null
      this.editOrganizationData.abstract = ''
      this.InitialRoleByProduceId(this.userInfo.productId)
    },
    EditOrganizationBtn(item) {
      this.OrganizationDialog = true
      this.OrganizationTitle = '编辑下级机构'
      this.editOrganizationData.id = item.id
      this.editOrganizationData.agentId = item.agentId
      this.editOrganizationData.name = item.name
      this.editOrganizationData.alias = item.alias
      this.editOrganizationData.address = item.address
      this.editOrganizationData.longitude = item.longitude
      this.editOrganizationData.latitude = item.latitude
      this.editOrganizationData.phone = item.phone
      this.editOrganizationData.mailAddress = item.mail
      this.editOrganizationData.contactName = item.contactName
      this.editOrganizationData.contactPhone = item.contactPhone
      this.editOrganizationData.levelId = item.level ? item.level.id : null
      this.editOrganizationData.typeId = item.type ? item.type.id : null
      // this.editOrganizationData.organizationManager.roleId = item.organizationManager.roleId;
      this.editOrganizationData.statu = item.statu
      this.editOrganizationData.productId = item.productId.toString()
      this.editOrganizationData.industryId = item.industryId ? item.industryId : null
      this.editOrganizationData.regionId = item.regionId
      this.editOrganizationData.region = item.region
      this.editOrganizationData.abstract = item.abstract
      this.editOrganizationData.parentId = item.parentId
      this.editOrganizationData.businessImage = item.businessImage
      this.editOrganizationData.idFrontImage = item.idFrontImage
      this.editOrganizationData.idBackImage = item.idBackImage
      this.editOrganizationData.distributionMode = item.distributionMode
      this.editOrganizationData.EditItem = item
      this.addressInfo.longitude = item.longitude
      this.addressInfo.latitude = item.latitude
      this.InitialRoleByProduceId(this.userInfo.productId)
    },
    ShowUser(id) {
      this.$router.push({
        path: '/User/Staff',
        query: {
          id: id,
        },
      })
    },
    isChecked(data) {
      var _this = this
      _this.disabled = data
      if (data) {
        _this.AddOrganzation.password = '123456'
        _this.AddOrganzation.confirmPwd = '123456'
      } else {
        _this.AddOrganzation.password = ''
        _this.AddOrganzation.confirmPwd = ''
      }
    },
    EnableOrganization(item) {
      var _this = this
      _this.OrganizationDomain.Enable(
        item.id,
        function (data) {
          item.statu = 1
          _this.$message({
            message: '已启用',
            type: 'success',
          })
        },
        function (err) {
          console.log(err)
        }
      )
    },
    DisableOrganization(item) {
      var _this = this
      _this.OrganizationDomain.Disable(
        item.id,
        function (data) {
          item.statu = -1
          _this.$message({
            message: '已停用',
            type: 'success',
          })
        },
        function (err) {
          console.log(err)
        }
      )
    },
  },
}
</script>

<style scoped>
.table-list {
  border-bottom: 1px solid #ebeef5;
  padding: 12px 10px;
  background-color: #ffffff;
}

.table-list:nth-child(even) {
  background-color: #fafafa;
}

.table-list:hover {
  background-color: #f5f7fa;
}

.list-mes {
  width: calc(100% - 120px);
}

.list-img {
  margin-right: 20px;
  width: 100px;
  height: 100px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  overflow: hidden;
}

.list-img img {
  width: 100%;
  height: 100%;
}

.btn-group {
  margin-top: 20px;
}

.right-num {
  margin-top: 30px;
}

.right-num a {
  color: #089bab;
}

#list-table {
  overflow: hidden;
}
.dialog-form {
  width: 430px;
}
/**/
.addhospital {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.addhospital .el-form {
  flex: 1;
}

.addhospital .form-box {
  padding: 30px 50px 30px 30px;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
}
.headImg {
  position: relative;
  margin-bottom: 25px;
}

.headimgBox {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  margin: 0 auto;
  overflow: hidden;
  border: 5px solid rgba(12, 154, 154, 0.28);
}

.headimgBox img {
  width: 100%;
}

.headImg .upload-btn {
  position: absolute;
  left: 57%;
  bottom: 0px;
}
.bm-view {
  display: block;
  width: 100%;
  height: 400px;
  margin-bottom: 20px;
}

.BMap_noprint.anchorTL {
  width: 22%;
  top: 10px !important;
  left: 10px !important;
}
.demo-drawer__footer {
  display: flex;
  margin-left: 100px;
}
</style>
<style>
.staff .el-drawer {
  background-color: #eff7f8;
}

.el-select-dropdown__wrap.el-scrollbar__wrap {
  overflow: scroll !important;
}
</style>
